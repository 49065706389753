import React from "react";
import Card from "../../../components/ProductDashboard/Card";
import SalesGraph from "../../../components/ProductDashboard/SalesGraph";
import DonutChart from "../../../components/ProductDashboard/DonutChart";
import Table from "../../../components/ProductDashboard/TableData";

const CrackersDashboard = () => {
  return (
    <div className="p-6 grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* <div className="col-span-2 grid grid-cols-2 lg:grid-cols-4 gap-4   ">
        <Card title="Sweets Sold" value="100 kgs" icon="assets/sweets.png" />
        <Card title="Spicy Sold" value="80 kgs" icon="assets/chilli.png" />
        <Card title="Nuts Sold" value="70 kgs" icon="assets/nuts.png" />
        <Card
          title="Total Snacks Sold"
          value="250 kgs"
          icon="assets/quantity.png"
        />
      </div> */}

      <div className="col-span-2 grid grid-cols-2   gap-4 ">
        <div>
          <SalesGraph category="Crackers"/>
        </div>
        <div>
          <DonutChart category="Crackers"/>
        </div>
      </div>

      <div className="col-span-2 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <Table />
        </div>

        <div className="col-span-1">
          <Table type="lowStock" />
        </div>
      </div>
    </div>
  );
};

export default CrackersDashboard;
