import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { BaseUrl } from "../../../App";
import { useNavigate } from "react-router-dom";

const NewProductForm = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      weightsPrices: [{ weight: "", price: "" }],
    },
  });

  const navigate = useNavigate();
  const productCode = watch("productCode");
  const [addCategory, setAddCategory] = useState(false);
  const [options, setOptions] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "weightsPrices",
  });

  const [isProductCodeValid, setIsProductCodeValid] = useState(false);
  const [checkingProductCode, setCheckingProductCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const checkProductCode = async (productCode) => {
    if (!productCode) return;

    setCheckingProductCode(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/product/not-active/${productCode}`
      );
      console.log(response);

      if (response.data) {
        setIsProductCodeValid(true);
        setOptions([response.data.productCategory]);
        setValue("productName", response.data.productName);
      } else {
        setIsProductCodeValid(true);
      }
    } catch (error) {
      console.error("Error checking product code", error);
      setIsProductCodeValid(false);
    } finally {
      setCheckingProductCode(false);
    }
  };

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BaseUrl}/api/admin/product/fetch-category?category=Snacks`
      );
      setOptions(response.data.categories);
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect to monitor productCode changes and debounce the API call
  useEffect(() => {
    fetchCategory();
    const debounceTimer = setTimeout(() => {
      checkProductCode(productCode);
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [productCode, setValue]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();

      // Append regular fields
      formData.append("productCode", data.productCode);
      formData.append("productCategory", data.productCategory);
      formData.append("productName", data.productName);
      formData.append("category", "Snacks"); // Hard-coded category for now
      formData.append("gst", data.gst);
      formData.append("mlmDiscount", data.mlmDiscount);
      formData.append("referralDiscount", data.referralDiscount);
      formData.append("normalDiscount", data.normalDiscount);

      // Append weights and prices
      data.weightsPrices.forEach((weightObj, index) => {
        formData.append(`weight[${index}][value]`, weightObj.weight);
        formData.append(`weight[${index}][unit]`, weightObj.unit);
        formData.append(`weight[${index}][price]`, weightObj.price);
      });

      // Append product images if they exist
      if (data.photo && data.photo.length) {
        Array.from(data.photo).forEach((file) => {
          formData.append("productImage", file);
        });
      }

      // Make API call
      const response = await axios.put(
        `${BaseUrl}/api/admin/product/update-instance/${data.productCode}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Product updated successfully:", response.data);

      // Redirect after success
      navigate(`/snacks/list/preview/${response.data.product._id}`);
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  // PRICE DISCOUNT CALCULATION LOGIC
  // Watch discount fields and weightsPrices
  const mlmDiscount = watch("mlmDiscount");
  const referralDiscount = watch("referralDiscount");
  const normalDiscount = watch("normalDiscount");
  const weightsPrices = watch("weightsPrices");

  // Determine the base price. Here, we're using the first price in weightsPrices.
  // Adjust this logic if discounts should apply differently.
  const basePrice = parseFloat(weightsPrices?.[0]?.price) || 0;

  // Calculate discount amounts
  const mlmDiscountAmount = (basePrice * (parseFloat(mlmDiscount) || 0)) / 100;
  const referralDiscountAmount =
    (basePrice * (parseFloat(referralDiscount) || 0)) / 100;
  const normalDiscountAmount =
    (basePrice * (parseFloat(normalDiscount) || 0)) / 100;

  // If handling multiple weights and prices, calculate discounts for each
  const discountDetails = weightsPrices.map((wp) => {
    const price = parseFloat(wp.price) || 0;
    return {
      weight: wp.weight,
      unit: wp.unit,
      originalPrice: price,
      mlmDiscountAmount: (price * (parseFloat(mlmDiscount) || 0)) / 100,
      referralDiscountAmount:
        (price * (parseFloat(referralDiscount) || 0)) / 100,
      normalDiscountAmount: (price * (parseFloat(normalDiscount) || 0)) / 100,
      mlmDiscountedPrice: (
        price -
        (price * (parseFloat(mlmDiscount) || 0)) / 100
      ).toFixed(2),
      referralDiscountedPrice: (
        price -
        (price * (parseFloat(referralDiscount) || 0)) / 100
      ).toFixed(2),
      normalDiscountedPrice: (
        price -
        (price * (parseFloat(normalDiscount) || 0)) / 100
      ).toFixed(2),
    };
  });

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-6">New Product</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Product Code */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Product Code
            </label>
            <input
              {...register("productCode", {
                required: "Product Code is required",
              })}
              type="text"
              className={`mt-1 block w-full p-2 border border-gray-300 
               rounded-md shadow-sm`}
              placeholder="Enter Product Code"
            />
            {errors.productCode && (
              <p className="text-red-500 text-sm">
                {errors.productCode.message}
              </p>
            )}
            {isProductCodeValid && (
              <p className="text-green-500 text-sm">Product details found</p>
            )}
            {checkingProductCode && (
              <p className="text-sm text-gray-500">Checking...</p>
            )}
          </div>

          {/* Product Category */}
          {addCategory ? (
            <div className="flex flex-col">
              <label className="text-gray-700">Product Category</label>
              <input
                type="text"
                {...register("productCategory", {
                  required: "Product Code is required",
                })}
                className="border p-2 rounded mt-1"
                placeholder="SN254"
              />
              {errors.productCategory && (
                <p className="text-red-500 text-sm">
                  {errors.productCategory.message}
                </p>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <label className="text-gray-700">Product Category</label>
                <a
                  onClick={() => setAddCategory(true)}
                  className="text-blue-600 text-sm ml-2"
                >
                  Add New Category
                </a>
              </div>
              <div className="flex items-center">
                <select
                  {...register("productCategory", {
                    required: "Product Category is required",
                  })}
                  className="border p-2 rounded mt-1 w-full capitalize"
                >
                  {options.map((itm, inx) => (
                    <option value={itm} className="capitalize">
                      {itm}
                    </option>
                  ))}
                </select>
              </div>
              {errors.productCategory && (
                <p className="text-red-500 text-sm">
                  {errors.productCategory.message}
                </p>
              )}
            </div>
          )}

          {/* Product Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Product Name
            </label>
            <input
              {...register("productName", {
                required: "Product Name is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Product Name"
            />
            {errors.productName && (
              <p className="text-red-500 text-sm">
                {errors.productName.message}
              </p>
            )}
          </div>

          {/* GST */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              GST
            </label>
            <input
              {...register("gst", { required: "GST is required" })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter GST"
            />
            {errors.gst && (
              <p className="text-red-500 text-sm">{errors.gst.message}</p>
            )}
          </div>

          {/* Dynamic Weight and Price */}
          <div className="col-span-2">
            <div className="flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-700">
                Weight & Price
              </label>
              <button
                type="button"
                className="text-blue-500 mt-2"
                onClick={() => append({ weight: "", price: "" })}
              >
                Add More
              </button>
            </div>
            {fields.map((field, index) => (
              <div key={field.id} className="flex flex-col space-y-2 mb-4">
                <div className="flex items-center space-x-4">
                  <div className="relative flex-grow">
                    {/* Flex container for weight input and unit */}
                    <div className="flex items-center border border-gray-300 rounded-md shadow-sm">
                      <input
                        {...register(`weightsPrices[${index}].weight`, {
                          required: "Weight is required",
                        })}
                        type="number"
                        placeholder="Weight"
                        className="w-full p-2 border-none rounded-l-md"
                      />
                      <select
                        {...register(`weightsPrices[${index}].unit`, {
                          required: "Unit is required",
                        })}
                        className="p-2 border-0 rounded-r-md"
                      >
                        <option value="kgs">kg</option>
                        <option value="gms">g</option>
                        {/* <option value="lb">lb</option>
                        <option value="oz">oz</option> */}
                      </select>
                    </div>
                  </div>

                  <input
                    {...register(`weightsPrices[${index}].price`, {
                      required: "Price is required",
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/,
                        message: "Invalid price format",
                      },
                      min: { value: 0, message: "Price cannot be negative" },
                    })}
                    type="number"
                    step="0.01"
                    placeholder="Price"
                    className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md shadow-sm"
                  />
                  {errors.weightsPrices?.[index]?.price && (
                    <p className="text-red-500 text-sm">
                      {errors.weightsPrices[index].price.message}
                    </p>
                  )}
                </div>

                {/* Display Discounted Prices for Each Weight-Price Pair */}
                {discountDetails[index] && (
                  <div className="ml-4 text-sm text-green-500">
                    <p>
                      MLM Discounted Price: ₹
                      {discountDetails[index].mlmDiscountedPrice}
                    </p>
                    <p>
                      Referral Discounted Price: ₹
                      {discountDetails[index].referralDiscountedPrice}
                    </p>
                    <p>
                      Normal Discounted Price: ₹
                      {discountDetails[index].normalDiscountedPrice}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* MLM Discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              MLM Discount
            </label>
            <input
              {...register("mlmDiscount", {
                required: "MLM Discount is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter MLM Discount"
            />
            {errors.mlmDiscount && (
              <p className="text-red-500 text-sm">
                {errors.mlmDiscount.message}
              </p>
            )}
          </div>

          {/* Referral Discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Referral Discount
            </label>
            <input
              {...register("referralDiscount", {
                required: "Referral Discount is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Referral Discount"
            />
            {errors.referralDiscount && (
              <p className="text-red-500 text-sm">
                {errors.referralDiscount.message}
              </p>
            )}
          </div>

          {/* Normal Discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Normal Discount
            </label>
            <input
              {...register("normalDiscount", {
                required: "Normal Discount is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Normal Discount"
            />
            {errors.normalDiscount && (
              <p className="text-red-500 text-sm">
                {errors.normalDiscount.message}
              </p>
            )}
          </div>

          {/* Photo Upload */}
          {/* Photo Upload */}
          <div className="mb-4 w-full">
            <label className="block mb-3 font-medium">Product Photos</label>
            <div
              className={`w-full border border-dashed border-blue-500 p-2 rounded-md text-center underline text-blue-500 cursor-pointer ${
                errors.photo ? "border-red-600 text-red-600" : ""
              }`}
              onClick={() => document.getElementById("photo").click()}
            >
              {watch("photo") && watch("photo").length > 0
                ? Array.from(watch("photo")).map((file, index) => (
                    <p key={index}>{file.name}</p>
                  ))
                : "Upload images"}
            </div>
            <input
              {...register("photo", {
                required: "Product Image is required",
              })}
              type="file"
              id="photo"
              className="hidden"
              multiple
            />
            {errors.photo && (
              <span className="text-red-600">{errors.photo.message}</span>
            )}
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-8 mt-6">
          <button
            type="button"
            className="text-red-500 py-2 px-4  font-semibold"
          >
            Discard
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewProductForm;
