import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { BaseUrl } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";

const UpdateProductForm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      weightsPrices: [{ weight: "", price: "" }],
    },
  });
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "weightsPrices",
  });

  const [currentImages, setCurrentImages] = useState([]);

  const [addCategory, setAddCategory] = useState(false);
  const [options, setOptions] = useState([]);

  // Fetch the existing product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/api/admin/product/details/${id}`
        );
        const product = response.data;

        // Set the form data with the fetched product details
        setValue("productCode", product.productCode);
        setValue("productCategory", product.productCategory);
        setValue("productName", product.productName);
        setValue("gst", product.gst);
        setValue("quantity", product.quantity);
        setValue("unit", product.unit);
        setValue("pieces", product.pieces);
        setValue("price", product.price);
        setValue("mlmDiscount", product.mlmDiscount);
        setValue("referralDiscount", product.referralDiscount);
        setValue("normalDiscount", product.normalDiscount);
        setValue("photo", product.photos);

        setCurrentImages(product.photos);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/api/admin/product/fetch-category?category=Crackers`
        );
        setOptions(response.data.categories);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
    fetchCategory();
  }, [id, setValue]);

  const handleReplaceImage = (e, index) => {
    const selectedFile = e.target.files[0]; // Get the selected file
    if (selectedFile) {
      const updatedImages = [...currentImages]; // Clone the current images
      updatedImages[index] = URL.createObjectURL(selectedFile); // Generate a preview URL for the image
      setCurrentImages(updatedImages); // Update the state with the new image URL
    }
  };

  // Update product
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("productCode", data.productCode);
      formData.append("productCategory", data.productCategory);
      formData.append("productName", data.productName);
      formData.append("category", "Crackers");
      formData.append("gst", data.gst);
      formData.append("quantity", data.quantity);
      formData.append("pieces", data.pieces);
      formData.append("price", data.price);
      formData.append("mlmDiscount", data.mlmDiscount);
      formData.append("referralDiscount", data.referralDiscount);
      formData.append("normalDiscount", data.normalDiscount);

      // if (data.photo) {
      //   Array.from(data.photo).forEach((file) => {
      //     formData.append("productImage", file);
      //   });
      // }

      // Update the product
      const response = await axios.put(
        `${BaseUrl}/api/admin/product/update-product/${id}`,
        data
      );
      toast.success("Stock updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <ToastContainer />
      <h2 className="text-xl font-semibold mb-6">Update Product</h2>
      {loading ? (
        <p>Loading product details...</p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Product Code */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Product Code
              </label>
              <input
                {...register("productCode", {
                  required: "Product Code is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Product Code"
              />
              {errors.productCode && (
                <p className="text-red-500 text-sm">
                  {errors.productCode.message}
                </p>
              )}
            </div>

            {/* Product Category */}
            {addCategory ? (
              <div className="flex flex-col">
                <label className="text-gray-700">Product Category</label>
                <input
                  type="text"
                  {...register("productCategory", {
                    required: "Product Category is required",
                  })}
                  className="border p-2 rounded mt-1"
                  placeholder="SN254"
                />
                {errors.productCategory && (
                  <p className="text-red-500 text-sm">
                    {errors.productCategory.message}
                  </p>
                )}
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <label className="text-gray-700">Product Category</label>
                  <a
                    onClick={() => setAddCategory(true)}
                    className="text-blue-600 text-sm ml-2"
                  >
                    Add New Category
                  </a>
                </div>
                <div className="flex items-center">
                  <select
                    {...register("productCategory", {
                      required: "Product Category is required",
                    })}
                    className="border p-2 rounded mt-1 w-full capitalize"
                  >
                    {options.map((itm, inx) => (
                      <option value={itm} className="capitalize">
                        {itm}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.productCategory && (
                  <p className="text-red-500 text-sm">
                    {errors.productCategory.message}
                  </p>
                )}
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Product Name
              </label>
              <input
                {...register("productName", {
                  required: "Product Name is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Product Name"
              />
              {errors.productName && (
                <p className="text-red-500 text-sm">
                  {errors.productName.message}
                </p>
              )}
            </div>

            {/* GST */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                GST
              </label>
              <input
                {...register("gst", { required: "GST is required" })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter GST"
              />
              {errors.gst && (
                <p className="text-red-500 text-sm">{errors.gst.message}</p>
              )}
            </div>

            <div className="relative flex-grow">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Quantity
              </label>

              <div className="flex items-center border border-gray-300 rounded-md shadow-sm">
                <input
                  {...register(`quantity`, {
                    required: "Quantity is required",
                  })}
                  type="number"
                  placeholder="Quantity"
                  className="w-full p-2  border-none rounded-l-md focus:ring-0"
                />
                <select
                  {...register(`unit`, {
                    required: "Unit is required",
                  })}
                  className="p-2  border-0 rounded-r-md"
                >
                  <option value="pack">Pack</option>
                  <option value="box">Box</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Pieces
              </label>
              <input
                {...register("pieces", { required: "pieces is required" })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter pieces"
              />
              {errors.gst && (
                <p className="text-red-500 text-sm">{errors.pieces.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Price
              </label>
              <input
                {...register("price", { required: "price is required" })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter price"
              />
              {errors.gst && (
                <p className="text-red-500 text-sm">{errors.price.message}</p>
              )}
            </div>
            <div></div>

            {/* MLM Discount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                MLM Discount
              </label>
              <input
                {...register("mlmDiscount", {
                  required: "MLM Discount is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter MLM Discount"
              />
              {errors.mlmDiscount && (
                <p className="text-red-500 text-sm">
                  {errors.mlmDiscount.message}
                </p>
              )}
            </div>

            {/* Referral Discount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Referral Discount
              </label>
              <input
                {...register("referralDiscount", {
                  required: "Referral Discount is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Referral Discount"
              />
              {errors.referralDiscount && (
                <p className="text-red-500 text-sm">
                  {errors.referralDiscount.message}
                </p>
              )}
            </div>

            {/* Normal Discount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Normal Discount
              </label>
              <input
                {...register("normalDiscount", {
                  required: "Normal Discount is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Normal Discount"
              />
              {errors.normalDiscount && (
                <p className="text-red-500 text-sm">
                  {errors.normalDiscount.message}
                </p>
              )}
            </div>

            {/* <div className="mb-4 w-full">
              <label className="block mb-3 font-medium">Product Photos</label>
              <div
                className={`w-full border border-dashed border-blue-500 p-2 rounded-md text-center underline text-blue-500 cursor-pointer ${
                  errors.photo ? "border-red-600 text-red-600" : ""
                }`}
                onClick={() => document.getElementById("photo").click()}
              >
                {watch("photo") && watch("photo").length > 0
                  ? Array.from(watch("photo")).map((file, index) => (
                      <p key={index}>{file.name}</p>
                    ))
                  : "Upload images"}
              </div>
              <input
                {...register("photo")}
                type="file"
                id="photo"
                className="hidden"
                multiple
              />
              {errors.photo && (
                <span className="text-red-600">{errors.photo.message}</span>
              )}
            </div> */}

            {/* <div className="mb-4 w-full">
            <label className="block mb-3 font-medium">Product Photos</label>

             
            <div className="flex gap-4 flex-wrap mb-3">
              {currentImages && currentImages.length > 0 ? (
                currentImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={`${BaseUrl}/${image}`} 
                      alt={`Product Image ${index + 1}`}
                      className="w-32 h-32 object-cover border border-gray-300 rounded-md"
                    />
                  
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-red-500 text-white px-1 py-0.5 rounded-full"
                      onClick={() =>
                        document
                          .getElementById(`replace-photo-${index}`)
                          .click()
                      }
                    >
                      Replace
                    </button>
                    <input
                      {...register(`photo_${index}`)}  
                      type="file"
                      id={`replace-photo-${index}`}
                      className="hidden"
                      onChange={(e) => handleReplaceImage(e, index)}  
                    />
                  </div>
                ))
              ) : (
                <p>No images found.</p>
              )}
            </div>

            
            <div
              className={`w-full border border-dashed border-blue-500 p-2 rounded-md text-center underline text-blue-500 cursor-pointer ${
                errors.photo ? "border-red-600 text-red-600" : ""
              }`}
              onClick={() => document.getElementById("photo").click()}
            >
              {watch("photo") && watch("photo").length > 0
                ? Array.from(watch("photo")).map((file, index) => (
                    <p key={index}>{file.name}</p>
                  ))
                : "Upload new images"}
            </div>
            <input
              {...register("photo")}
              type="file"
              id="photo"
              className="hidden"
              multiple
            />
            {errors.photo && (
              <span className="text-red-600">{errors.photo.message}</span>
            )}
          </div> */}
          </div>

          {/* Buttons */}
          <div className="flex justify-end gap-8 mt-6">
            <button
              type="button"
              className="text-red-500 py-2 px-4  font-semibold"
            >
              Discard
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdateProductForm;
