import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { BaseUrl } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";

const SnacksUpdateProductForm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      weightsPrices: [{ value: "", unit: "kgs", price: "" }],
    },
  });
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "weightsPrices",
  });

  const [currentImages, setCurrentImages] = useState([]);

  const [addCategory, setAddCategory] = useState(false);
  const [options, setOptions] = useState([]);

  // Fetch the existing product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/api/admin/product/details/${id}`
        );
        const product = response.data;

        console.log(product);

        // Set the form data with the fetched product details
        setValue("productCode", product.productCode);
        setValue("productCategory", product.productCategory);
        setValue("productName", product.productName);
        setValue("gst", product.gst);
        setValue("price", product.price);
        setValue("mlmDiscount", product.mlmDiscount);
        setValue("referralDiscount", product.referralDiscount);
        setValue("normalDiscount", product.normalDiscount);
        setValue("photo", product.photos);

        if (product.weight) {
          const weightPrices = product.weight.map((wp) => ({
            value: wp.value,
            price: wp.price,
            unit: wp.unit,
          }));

          // Set the 'weightsPrices' array in the form
          setValue("weightsPrices", weightPrices);
        }

        setCurrentImages(product.photos);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/api/admin/product/fetch-category?category=Snacks`
        );
        setOptions(response.data.categories);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
    fetchCategory();
  }, [id, setValue]);

  // Update product
  const onSubmit = async (data) => {
    try {
      console.log(data);

      const formData = new FormData();
      formData.append("productCode", data.productCode);
      formData.append("productCategory", data.productCategory);
      formData.append("productName", data.productName);
      formData.append("category", "Crackers");
      formData.append("gst", data.gst);
      formData.append("mlmDiscount", data.mlmDiscount);
      formData.append("referralDiscount", data.referralDiscount);
      formData.append("normalDiscount", data.normalDiscount);

      data.weightsPrices.forEach((weightObj, index) => {
        formData.append(`weight[${index}][value]`, weightObj.value);
        formData.append(`weight[${index}][unit]`, weightObj.unit);
        formData.append(`weight[${index}][price]`, weightObj.price);
      });
      // if (data.photo) {
      //   Array.from(data.photo).forEach((file) => {
      //     formData.append("productImage", file);
      //   });
      // }

      // Update the product
      const response = await axios.put(
        `${BaseUrl}/api/admin/product/update-product/${id}`,
        data
      );
      toast.success("Stock updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  // DISCOUNT CALCULATION
  // Watch discount fields and weightsPrices
  const mlmDiscount = watch("mlmDiscount");
  const referralDiscount = watch("referralDiscount");
  const normalDiscount = watch("normalDiscount");
  const weightsPrices = watch("weightsPrices");

  // Determine the base price. Here, we're using the first price in weightsPrices.
  // Adjust this logic if discounts should apply differently.
  const basePrice = parseFloat(weightsPrices?.[0]?.price) || 0;

  // Calculate discount amounts
  const mlmDiscountAmount = (basePrice * (parseFloat(mlmDiscount) || 0)) / 100;
  const referralDiscountAmount =
    (basePrice * (parseFloat(referralDiscount) || 0)) / 100;
  const normalDiscountAmount =
    (basePrice * (parseFloat(normalDiscount) || 0)) / 100;

  // If handling multiple weights and prices, calculate discounts for each
  const discountDetails = weightsPrices.map((wp) => {
    const price = parseFloat(wp.price) || 0;
    return {
      weight: wp.weight,
      unit: wp.unit,
      originalPrice: price,
      mlmDiscountAmount: (price * (parseFloat(mlmDiscount) || 0)) / 100,
      referralDiscountAmount:
        (price * (parseFloat(referralDiscount) || 0)) / 100,
      normalDiscountAmount: (price * (parseFloat(normalDiscount) || 0)) / 100,
      mlmDiscountedPrice: (
        price -
        (price * (parseFloat(mlmDiscount) || 0)) / 100
      ).toFixed(2),
      referralDiscountedPrice: (
        price -
        (price * (parseFloat(referralDiscount) || 0)) / 100
      ).toFixed(2),
      normalDiscountedPrice: (
        price -
        (price * (parseFloat(normalDiscount) || 0)) / 100
      ).toFixed(2),
    };
  });
  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <ToastContainer />
      <h2 className="text-xl font-semibold mb-6">Update Product</h2>
      {loading ? (
        <p>Loading product details...</p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Product Code */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Product Code
              </label>
              <input
                {...register("productCode", {
                  required: "Product Code is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Product Code"
              />
              {errors.productCode && (
                <p className="text-red-500 text-sm">
                  {errors.productCode.message}
                </p>
              )}
            </div>

            {/* Product Category */}
            {addCategory ? (
              <div className="flex flex-col">
                <label className="text-gray-700">Product Category</label>
                <input
                  type="text"
                  {...register("productCategory", {
                    required: "Product Category is required",
                  })}
                  className="border p-2 rounded mt-1"
                  placeholder="SN254"
                />
                {errors.productCategory && (
                  <p className="text-red-500 text-sm">
                    {errors.productCategory.message}
                  </p>
                )}
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <label className="text-gray-700">Product Category</label>
                  <a
                    onClick={() => setAddCategory(true)}
                    className="text-blue-600 text-sm ml-2"
                  >
                    Add New Category
                  </a>
                </div>
                <div className="flex items-center">
                  <select
                    {...register("productCategory", {
                      required: "Product Category is required",
                    })}
                    className="border p-2 rounded mt-1 w-full capitalize"
                  >
                    {options.map((itm, inx) => (
                      <option value={itm} className="capitalize">
                        {itm}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.productCategory && (
                  <p className="text-red-500 text-sm">
                    {errors.productCategory.message}
                  </p>
                )}
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Product Name
              </label>
              <input
                {...register("productName", {
                  required: "Product Name is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Product Name"
              />
              {errors.productName && (
                <p className="text-red-500 text-sm">
                  {errors.productName.message}
                </p>
              )}
            </div>

            {/* GST */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                GST
              </label>
              <input
                {...register("gst", { required: "GST is required" })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter GST"
              />
              {errors.gst && (
                <p className="text-red-500 text-sm">{errors.gst.message}</p>
              )}
            </div>

            {/* dynamic price input fields */}
            <div className="col-span-2">
              <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-700">
                  Weight & Price
                </label>
                <button
                  type="button"
                  className="text-blue-500 mt-2"
                  onClick={() => append({ weight: "", price: "", unit: "kgs" })} // Adding a new field
                >
                  Add More
                </button>
              </div>

              {fields.map((field, index) => (
                <div key={field.id} className="flex flex-col space-y-2 mb-4">
                  <div className="flex items-center space-x-4">
                    <div className="relative flex-grow">
                      <div className="flex items-center border border-gray-300 rounded-md shadow-sm">
                        <input
                          {...register(`weightsPrices[${index}].value`, {
                            required: "Weight is required",
                          })}
                          type="number"
                          placeholder="Weight"
                          className="w-full p-2 border-none rounded-l-md"
                        />
                        <select
                          {...register(`weightsPrices[${index}].unit`, {
                            required: "Unit is required",
                          })}
                          className="p-2 border-0 rounded-r-md"
                        >
                          <option value="kgs">kg</option>
                          <option value="gms">g</option>
                        </select>
                      </div>
                    </div>

                    <input
                      {...register(`weightsPrices[${index}].price`, {
                        required: "Price is required",
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message: "Invalid price format",
                        },
                        min: { value: 0, message: "Price cannot be negative" },
                      })}
                      type="number"
                      step="0.01"
                      placeholder="Price"
                      className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    {errors.weightsPrices?.[index]?.price && (
                      <p className="text-red-500 text-sm">
                        {errors.weightsPrices[index].price.message}
                      </p>
                    )}
                  </div>

                  {/* Display Discounted Prices for Each Weight-Price Pair */}
                  {discountDetails[index] && (
                    <div className="ml-4 text-sm text-green-500">
                      <p>
                        MLM Discounted Price: ₹
                        {discountDetails[index].mlmDiscountedPrice}
                      </p>
                      <p>
                        Referral Discounted Price: ₹
                        {discountDetails[index].referralDiscountedPrice}
                      </p>
                      <p>
                        Normal Discounted Price: ₹
                        {discountDetails[index].normalDiscountedPrice}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div></div>

            {/* MLM Discount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                MLM Discount
              </label>
              <input
                {...register("mlmDiscount", {
                  required: "MLM Discount is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter MLM Discount"
              />
              {errors.mlmDiscount && (
                <p className="text-red-500 text-sm">
                  {errors.mlmDiscount.message}
                </p>
              )}
            </div>

            {/* Referral Discount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Referral Discount
              </label>
              <input
                {...register("referralDiscount", {
                  required: "Referral Discount is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Referral Discount"
              />
              {errors.referralDiscount && (
                <p className="text-red-500 text-sm">
                  {errors.referralDiscount.message}
                </p>
              )}
            </div>

            {/* Normal Discount */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Normal Discount
              </label>
              <input
                {...register("normalDiscount", {
                  required: "Normal Discount is required",
                })}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Enter Normal Discount"
              />
              {errors.normalDiscount && (
                <p className="text-red-500 text-sm">
                  {errors.normalDiscount.message}
                </p>
              )}
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-end gap-8 mt-6">
            <button
              type="button"
              className="text-red-500 py-2 px-4  font-semibold"
            >
              Discard
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SnacksUpdateProductForm;
