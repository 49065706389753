import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../App";
import Spinners from "../../../components/placeholders/Spinners";
import Pagination from "../../../components/Helpers/Pagination";
import moment from "moment";

const OrdersList = (prop) => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedDate, setSelectedDate] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    fetchSectionData();
  }, [searchQuery, currentPage, selectedCategory, selectedStatus,selectedDate,prop.category]);

  const fetchSectionData = async (page = 1, limit = 10, search = "") => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseUrl}/api/admin/orders`, {
        params: {
          search: searchQuery,
          orderStatus : "Completed",
          date:selectedDate,
          page: currentPage,
          limit: 9,
          category :prop.category
        },
      });

      setProducts(response.data.orders);
      setTotalPages(response.data.totalPages);
      setError(null);
    } catch (error) {
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);  
  };

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <div className="flex justify-end items-center mb-4">
        {/* <h2 className="text-xl font-bold text-blue-500">Orders</h2> */}

        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <div className="flex gap-2">
            <input
              type="date"
              value={selectedDate}  
              onChange={handleDateChange}  
              className="block w-54 py-1 ps-8 text-sm text-gray-900 rounded bg-white focus:ring-lavender--600 focus:border-lavender--600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lavender--600 dark:focus:border-lavender--600"
            />
            <input
              type="search"
              id="default-search"
              className="block w-54 py-1 ps-8 text-sm text-gray-900 rounded bg-white focus:ring-lavender--600 focus:border-lavender--600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lavender--600 dark:focus:border-lavender--600"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      {products.length === 0 ? (
        <p className="text-center text-gray-500">No products found</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2">Sl. no.</th>
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Location</th>
              <th className="px-4 py-2">Contact Number</th>
              <th className="px-4 py-2">Items</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Status</th>
            </tr>
          </thead>
          {loading ? (
            <div>
              <Spinners />
            </div>
          ) : (
            <tbody>
              {products.map((product, index) => (
                <tr
                  key={product.id}
                  onClick={() => navigate(`details/${product._id}`)}
                  className="border-t border-gray-200 text-gray-700 hover:bg-gray-50 cursor-pointer capitalize  text-center  "
                >
                  <td className="px-4 py-4 text-center">{index + 1}</td>
                  <td className="px-4 py-2">
                    {moment(new Date(product?.createdAt)).format("DD-MM-YYYY")}
                  </td>

                  <td className="px-4 py-2">
                    {product.shippingAddress?.firstName}
                  </td>
                  <td className="px-4 py-2">{product.shippingAddress?.city}</td>
                  <td className="px-4 py-2">
                    {product.shippingAddress?.phoneNumber}
                  </td>
                  {product?.items.map((itm) => (
                    <>
                      <td className="px-4 py-2">{itm.quantity}</td>
                    </>
                  ))}

                  <td className="px-4 py-2 text-center">
                    {product.totalAmount}
                  </td>

                  <td className="px-4 py-2 text-center font-bold">
                    <span
                      className={`px-2 py-1 rounded-full ${
                        product.status === "Dispatched"
                          ? "  text-green-500"
                          : product.status === "Cancelled"
                          ? "  text-red-800"
                          : "  text-yellow-500"
                      }`}
                    >
                      {product.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}

      <div className="py-2">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default OrdersList;
