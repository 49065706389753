import React, { useEffect, useState } from "react";
import Card from "../../../components/ProductDashboard/Card";
import SalesGraph from "../../../components/ProductDashboard/SalesGraph";
import DonutChart from "../../../components/ProductDashboard/DonutChart";
import Table from "../../../components/ProductDashboard/TableData";
import { BaseUrl } from "../../../App";
import axios from "axios";

const SnacksDashboard = () => {
  const [category, setCategory] = useState();
  const [totalQuantity, setTotalQuantity] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/api/admin/product-dashboard/snacks-categories`
        );
        setCategory(response.data.data.categories);
        setTotalQuantity(response.data.data.combinedTotalQuantity
        )
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  console.log(totalQuantity);
  
  return (
    <div className="p-6 grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="col-span-2 grid grid-cols-2 lg:grid-cols-4 gap-4   ">
        <Card title={category && category[0]?.productCategory} value={category && category[0]?.totalQuantity} icon="assets/sweets.png" />
        {category?.length >=2 && 
        <Card title={category && category[1]?.productCategory} value={category && category[1]?.totalQuantity} icon="assets/chilli.png" />}
        {category?.length >=3 && 
        <Card title="Nuts Sold" value="70 kgs" icon="assets/nuts.png" />}
        <Card
          title="Total Snacks Sold"
          value={totalQuantity}
          icon="assets/quantity.png"
        />
      </div>

      <div className="col-span-2 grid grid-cols-2   gap-4 ">
        <div>
          <SalesGraph category="Snacks" />
        </div>
        <div>
          <DonutChart category="Snacks" />
        </div>
      </div>

      <div className="col-span-2 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <Table />
        </div>

        <div className="col-span-1">
          <Table type="lowStock" />
        </div>
      </div>
    </div>
  );
};

export default SnacksDashboard;
