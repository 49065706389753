import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../App";
import Spinners from "../../../components/placeholders/Spinners";
import Pagination from "../../../components/Helpers/Pagination";
import moment from "moment";
import { useForm } from "react-hook-form";
import CustomModal from "../../../components/modals/Modal";
import { Config } from "../../../utils/Auth";

const Delivery = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverError, setServerError] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
    setServerError("");
    reset();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setServerError("");
    setSelectedOrder(null);
    reset();
  };

  useEffect(() => {
    fetchSectionData();
  }, [searchQuery, currentPage, selectedCategory, selectedStatus]);

  const fetchSectionData = async (page = 1, limit = 10, search = "") => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseUrl}/api/admin/orders`, {
        params: {
          search: searchQuery,
          page: currentPage,
          orderStatus : "Completed",
          limit: 9
        },
      });

      setProducts(response.data.orders);
      setTotalPages(response.data.totalPages);
      setError(null);
    } catch (error) {
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.put(
        `${BaseUrl}/api/admin/orders/dispatch/${selectedOrder._id}`,
        data,
        Config()
      );
      reset();
      // setIsModalOpen(false);
      handleCloseModal();
      fetchSectionData();
      setServerError("");
    } catch (error) {
      setServerError(error?.response?.data?.message);
      // if (error.response && error.response.status === 403) {
      //   setSectionExpired(true);
      // }
    }
  };

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <div className="flex justify-end items-center mb-4">
        {/* <h2 className="text-xl font-bold text-blue-500">Delivery Tracking</h2> */}

        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-54 py-1 ps-8 text-sm text-gray-900 rounded bg-white focus:ring-lavender--600 focus:border-lavender--600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lavender--600 dark:focus:border-lavender--600"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {products.length === 0 ? (
        <p className="text-center text-gray-500">No products found</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2">Sl. no.</th>
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Invoice number</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Location</th>
              <th className="px-4 py-2">Contact Number</th>
              <th className="px-4 py-2">Products</th>
              <th className="px-4 py-2">Tracking ID</th>
            </tr>
          </thead>
          {loading ? (
            <div>
              <Spinners />
            </div>
          ) : (
            <tbody>
              {products.map((product, index) => (
                <tr
                  key={product.id}
                  className="border-t border-gray-200 text-gray-700 hover:bg-gray-50 cursor-pointer capitalize  text-center  "
                >
                  <td className="px-4 py-4 text-center">{index + 1}</td>
                  <td className="px-4 py-2">
                    {moment(new Date(product?.createdAt)).format("DD-MM-YYYY")}
                  </td>

                  <td className="px-4 py-2">{product.orderId}</td>
                  <td className="px-4 py-2">
                    {product.shippingAddress?.firstName}
                  </td>
                  <td className="px-4 py-2">{product.shippingAddress?.city}</td>

                  <td className="px-4 py-2">{product?.items?.length}</td>

                  <td className="px-4 py-2 text-center">
                    {product.totalAmount}
                  </td>
                  <td className="px-4 py-2 text-center">
                    {product.trackingId || (
                      <button
                        className="text-blue-500 underline"
                        onClick={() => handleOpenModal(product)}
                      >
                        Enter Tracking Id
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}

      <div className="py-2">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-4">
          <h4 className="text-sm font-medium">
            Invoice Number: {selectedOrder?.orderId}
          </h4>
          <h4 className="text-sm font-medium">
            Customer Name: {selectedOrder?.shippingAddress?.firstName}
          </h4>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="trackingId"
                className="block text-sm font-medium text-gray-800 mb-5"
              >
                Track Number
              </label>
              <input
                id="trackingId"
                type="text"
                placeholder="Enter tracking id"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("trackingId", {
                  required: "Tracking Id  is required",
                })}
              />
              {errors.trackingId && (
                <p className="text-red-500 text-sm">
                  {errors.trackingId.message}
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="deliveryPartner"
                className="block text-sm font-medium text-gray-800 mb-5"
              >
                Delivery Partner
              </label>
              <input
                id="deliveryPartner"
                type="text"
                placeholder="Enter delivery partner"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("deliveryPartner", {
                  required: "Delivery partner is required",
                })}
              />
              {errors.deliveryPartner && (
                <p className="text-red-500 text-sm">
                  {errors.deliveryPartner.message}
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="trackingLink"
                className="block text-sm font-medium text-gray-800 mb-5"
              >
                Track link
              </label>
              <input
                id="trackingLink"
                type="text"
                placeholder="Enter tracking link"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("trackingLink", {
                  required: "Track link is required",
                })}
              />
              {errors.trackingLink && (
                <p className="text-red-500 text-sm">
                  {errors.trackingLink.message}
                </p>
              )}
            </div>
          </div>

          <div className="mt-4 flex gap-3 justify-end">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-red-500 focus:outline-none focus:ring-0 sm:text-sm"
              onClick={handleCloseModal}
            >
              Discard
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-14 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-0 sm:text-sm"
            >
              Save
            </button>
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default Delivery;
