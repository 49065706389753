import React, { useState } from "react";
import { IoIosEye } from "react-icons/io";

// import DetailModal from "./detailsModal";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinners from "../placeholders/Spinners";

const tableHeading = [
  "Sl. no.",
  "Date",
  "Category",
  "Sub Category",
  "Spent For",
  "Amount",
  "Status",
];

export default function ExpenseTables({ expenses, loading, currentPage }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowDetails = async (member) => {
    // try {
    //   setIsModalOpen(true);
    //   setIsLoading(true); // Set loading to true when fetching starts
    //   const response = await axios.get(`${URL}/expense/${member._id}`, {
    //     headers: {
    //       Authorization: token,
    //     },
    //   });
    //   setSelectedMember(response.data);
    // } catch (error) {
    //   console.error("Error fetching expense details:", error);
    //   if (error.response.status === 401) {
    //     window.localStorage.clear();
    //     naviagte("/");
    //   }
    // } finally {
    //   setIsLoading(false);
    // }
  };

  if (loading) {
    return <Spinners />;
  }

  return (
    <div className="overflow-x-auto">
      {expenses?.length === 0 ? (
        <div className="flex items-center justify-center">
          <p>No results found</p>
        </div>
      ) : (
        <>
          <table className="min-w-full mt-8">
            <thead>
              <tr>
                {tableHeading?.map((heading) => (
                  <th className="p-2 font-bold text-left" key={heading}>
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {expenses &&
                expenses.map((member, index) => (
                  <tr
                    key={member._id}
                    className="border-t text-[15px] border-gray-200"
                  >
                    <td className="p-2 py-3 text-gray-600 text-left">
                      {(currentPage - 1) * 10 + index + 1}
                    </td>
                    <td className="p-2 text-gray-600 text-left">
                      {moment(new Date(member?.date)).format("DD-MM-YYYY")}
                    </td>
                    <td className="p-2 text-gray-600 text-left">
                      {member.category}
                    </td>
                    <td className="p-2 text-gray-600 text-left">
                      {member.subCategory}
                    </td>
                    <td className="p-2 text-gray-600 text-left">
                      {member.spentFor}
                    </td>
                    <td className="p-2 text-gray-600 text-left">
                      {member.amount}
                    </td>
                    <td
                      className={`p-2 capitalize font-medium ${
                        member.status === "paid"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {member.status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* <DetailModal isOpen={isModalOpen} onClose={handleCloseModal}>
            {isLoading ? (
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-2 animate-pulse">
                  <span className="font-medium break-words bg-gray-300 h-6 w-20 rounded"></span>
                  <span className="text-gray-600 break-words bg-gray-300 h-6 w-40 rounded"></span>
                </div>
                <div className="grid grid-cols-2 animate-pulse">
                  <span className="break-words bg-gray-300 h-6 w-20 rounded"></span>
                  <span className="text-gray-600 break-words bg-gray-300 h-6 w-40 rounded"></span>
                </div>
                <div className="grid grid-cols-2 animate-pulse">
                  <span className="break-words bg-gray-300 h-6 w-20 rounded"></span>
                  <span className="text-gray-600 break-words bg-gray-300 h-6 w-40 rounded"></span>
                </div>
                <div className="grid grid-cols-2 animate-pulse">
                  <span className="break-words bg-gray-300 h-6 w-20 rounded"></span>
                  <span className="text-gray-600 break-words bg-gray-300 h-6 w-40 rounded"></span>
                </div>
                <div className="animate-pulse bg-gray-300 h-48 w-full rounded"></div>
              </div>
            ) : selectedMember ? (
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-2">
                  <span className="font-medium break-words">Date</span>
                  <span className="text-gray-600 break-words">
                    {moment(new Date(selectedMember?.date)).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span className="break-words">Category</span>
                  <span className="text-gray-600 break-words">
                    {selectedMember.category}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span className="break-words">Amount</span>
                  <span className="text-gray-600 break-words">
                    ₹ {selectedMember.amount}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span className="break-words">Description</span>
                  <span className="text-gray-600 break-words">
                    {selectedMember.description}
                  </span>
                </div>
                {selectedMember.image && (
                  <ImageRenderer imageBuffer={selectedMember.image} />
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <p>No details available</p>
              </div>
            )}
          </DetailModal> */}
        </>
      )}
    </div>
  );
}
