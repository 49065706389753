import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"; // Import Axios
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { BaseUrl } from "../../../App";
import Spinners from "../../../components/placeholders/Spinners";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { LuUpload } from "react-icons/lu";

const SnacksProductPreview = () => {
  const [status, setStatus] = useState("Active");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/product/details/${id}`
      );
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${BaseUrl}/api/admin/product/update-status/${id}`,
        { status: status }
      );
      setFormData(response.data);
      toast.success("Product status updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error while updating:", error);
    }
  };

  const previewData = [
    {
      key: "Product code",
      value: formData?.productCode || "N/A",
    },
    {
      key: "Product Category",
      value: formData?.productCategory || "N/A",
    },
    {
      key: "Product name",
      value: formData?.productName || "N/A",
    },
    {
      key: "GST",
      value: formData?.gst || "N/A",
    },
    {
      key: "MLM Discount",
      value: formData?.mlmDiscount ? `${formData.mlmDiscount} %` : "N/A",
    },
    {
      key: "Weight",
      value: formData?.weight?.length
        ? formData.weight.map((itm) => `${itm.value} ${itm.unit}`).join(", ")
        : "N/A",
    },
    {
      key: "Price",
      value: formData?.weight?.length
        ? formData.weight.map((itm) => `${itm.price} Rs`).join(", ")
        : "N/A",
    },
  ];

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];

    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (file && validImageTypes.includes(file.type)) {
      const numericIndex = Number(index);

      const updatedPhotos = [...formData.photos];
      updatedPhotos[numericIndex] = URL.createObjectURL(file);
      setFormData({ ...formData, photos: updatedPhotos });

      const formDataToSend = new FormData();
      formDataToSend.append("productImage", file);
      formDataToSend.append("index", numericIndex);

      try {
        setLoading(true);

        const response = await axios.put(
          `${BaseUrl}/api/admin/product/update-image/${id}`,
          formDataToSend
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully", response.data);
          fetchProduct();
        } else {
          console.error("Image upload failed", response.data);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please upload a valid image file (JPEG, PNG, GIF)");
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        {" "}
        <Spinners />
      </div>
    );
  }

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <ToastContainer />
      <div className="grid grid-cols-2 mb-4">
        <h2 className="text-xl font-bold">New Product</h2>
        <div className="flex justify-end space-x-10 font-semibold ">
          <button className="text-red-500 hover:underline flex items-center gap-2">
            <RiDeleteBin6Line /> Delete
          </button>
          <button
            className="text-green-500 hover:underline flex items-center gap-2"
            onClick={() => navigate(`/snacks/list/edit/${formData._id}`)}
          >
            <CiEdit /> Edit
          </button>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col w-1/2 gap-5 ">
          {previewData.map((p, index) => (
            <div className="grid grid-cols-2" key={index}>
              <label className="font-medium">{p?.key}</label>
              <p className="capitalize text-gray-900">{p?.value}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-5 grid grid-cols-2 w-3/4">
        <label className="font-medium">Status</label>
        <div className="flex items-center space-x-10 mt-2 font-semibold">
          <label className="flex items-center">
            <input
              type="radio"
              name="status"
              value="active"
              checked={formData.status === "active"}
              onChange={() => {
                setStatus("active");
                setFormData((prevData) => ({ ...prevData, status: "active" })); // Update formData
              }}
              className="form-radio text-green-500"
            />
            <span className="ml-2 text-green-500">Active</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="status"
              value="inactive"
              checked={formData.status === "inactive"}
              onChange={() => {
                setStatus("inactive");
                setFormData((prevData) => ({
                  ...prevData,
                  status: "inactive",
                })); // Update formData
              }}
              className="form-radio text-red-500"
            />
            <span className="ml-2 text-red-500">Inactive</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="status"
              value="out of stock"
              checked={formData.status === "out of stock"}
              onChange={() => {
                setStatus("out of stock");
                setFormData((prevData) => ({
                  ...prevData,
                  status: "out of stock",
                })); // Update formData
              }}
              className="form-radio text-yellow-500"
            />
            <span className="ml-2 text-yellow-500">Out of Stock</span>
          </label>
        </div>
      </div>

      <div className="mt-6">
        <label className="font-medium">Photos</label>
        <div className="flex items-center space-x-4 mt-4">
          {formData.photos.map((img, ind) => (
            <div key={ind} className="relative">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                id={`fileInput-${ind}`}
                onChange={(e) => handleImageUpload(e, ind)}
              />

              <LuUpload
                className="absolute border rounded-full p-1 border-red-500 top-0 left-0 m-1 cursor-pointer"
                color="red"
                size={25}
                onClick={() =>
                  document.getElementById(`fileInput-${ind}`).click()
                }
              />
              <img
                src={`${BaseUrl}/${img}`}
                alt="Product"
                className="h-24 w-24 object-cover rounded-lg border"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-end justify-end">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          onClick={handleUpdate}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default SnacksProductPreview;
