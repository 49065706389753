import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import OrdersList from "./OrdersList";

export default function OrdersTab() {
  const navigate = useNavigate();
  const { name } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "Crackers";

  const handleTabChange = (tab) => {
    setSearchParams({ tab });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab]);

  const renderContent = () => {
    switch (selectedTab) {
      case "Crackers":
        return <OrdersList category="Crackers" />;

      case "Snacks":
        return <OrdersList category="Snacks" />;

      default:
        return null;
    }
  };

  return (
    <div className="m-3 ">
      <div className="flex items-center space-x-8">
        <MdOutlineKeyboardBackspace
          size={30}
          className="my-3 text-gray-600 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <span className="text-xl text-blue-500 font-medium roboto-c">
          Orders
        </span>
      </div>

      {/* nav */}
      <div className="bg-white rounded-xl shadow-md px-5">
        <ul className="flex gap-10 text-gray-600 font-medium">
          <li
            className={`p-4 cursor-pointer${
              selectedTab === "Crackers"
                ? " text-blue-500 border-b-2 border-blue-500"
                : ""
            }`}
            onClick={() => handleTabChange("Crackers")}
          >
            Crackers
          </li>
          <li
            className={`p-4 cursor-pointer${
              selectedTab === "Snacks"
                ? " text-blue-500 border-b-2 border-blue-500"
                : ""
            }`}
            onClick={() => handleTabChange("Snacks")}
          >
            Snacks
          </li>
        </ul>
      </div>

      {/* render items */}
      <div className="mt-5">{renderContent()}</div>
    </div>
  );
}
