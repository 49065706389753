import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../App";
import Spinners from "../../../components/placeholders/Spinners";
import Pagination from "../../../components/Helpers/Pagination";
import moment from "moment";

export default function SnacksStock() {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [options, setOptions] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    fetchSectionData();
  }, [searchQuery, currentPage, selectedCategory, selectedStatus]);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchSectionData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/product/all-products`,
        {
          params: {
            category: "Snacks",
            productCategory:
              selectedCategory !== "all" ? selectedCategory : undefined,
            status: selectedStatus !== "all" ? selectedStatus : undefined,
            search: searchQuery,
            page: currentPage,
            limit: 9,
          },
        }
      );

      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setError(null);
    } catch (error) {
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BaseUrl}/api/admin/product/fetch-category?category=Snacks`
      );
      setOptions(response.data.categories);
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  // if (loading) {
  //   return <Spinners />;
  // }

  return (
    <div className="m-3 p-5 bg-white  shadow-md rounded-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Stock</h2>

        <div className="flex space-x-4">
          <select
            className="border rounded-md px-2 py-1 capitalize"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="all">Category: All</option>
            {options.map((itm, inx) => (
              <option value={itm} className="capitalize">
                {itm}
              </option>
            ))}
            {/* Add more category options */}
          </select>
        </div>

        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-54 py-1 ps-8 text-sm text-gray-900 rounded bg-white focus:ring-lavender--600 focus:border-lavender--600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lavender--600 dark:focus:border-lavender--600"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <Link to="new">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg">
            New Product
          </button>
        </Link>
      </div>

      {products.length === 0 ? (
        <p className="text-center text-gray-500">No products found</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2">Sl. no.</th>
              <th className="px-4 py-2">Product Code</th>
              <th className="px-4 py-2">Category</th>
              <th className="px-4 py-2">Product</th>
              <th className="px-4 py-2">Available Quantity</th>
              <th className="px-4 py-2">Updated On</th>
            </tr>
          </thead>
          {loading ? (
            <div>
              <Spinners />
            </div>
          ) : (
            <tbody>
              {products.map((product, index) => (
                <tr
                  key={product.id}
                  
                  className="border-t text-center border-gray-200 text-gray-700 hover:bg-gray-50 cursor-pointer capitalize"
                >
                  <td className="px-4 py-2 text-center">{index + 1}</td>

                  <td className="px-4 py-2">{product.productCode}</td>
                  <td className="px-4 py-2">{product.productCategory}</td>
                  <td className="px-4 py-2">{product.productName}</td>
                  <td className="px-4 py-2">{product.totalQuantity} KG</td>

                  <td className="px-4 py-2">
                    {moment(new Date(product?.updatedAt)).format("DD-MM-YYYY")}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => navigate(`update/${product._id}`)}
                      className="border text-sm border-blue-500 p-2 font-semibold rounded-lg text-blue-500"
                    >
                      Add More
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}

      <div className="py-2">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
