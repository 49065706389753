import { Tooltip } from "recharts";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { BaseUrl } from "../../App";

const SalesGraph = ({ category }) => {
  const [timePeriod, setTimePeriod] = useState("Week");
  const [data, setData] = useState([]);
  const [yDomain, setYDomain] = useState([0, 300]);

  const handleChange = (e) => {
    setTimePeriod(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/api/admin/product-dashboard/total-sales/?category=${category}&interval=${timePeriod.toLowerCase()}`
        );

        if (timePeriod === "Week") {
          setYDomain([0, 300]);
        } else if (timePeriod === "Month") {
          setYDomain([0, 1000]);
        } else {
          setYDomain([0, 2500]);
        }

        console.log(response.data);

        setData(response.data);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
  }, [timePeriod]);

  let dataKey;
  if (timePeriod === "Week") {
    dataKey = "day";
  } else if (timePeriod === "Month") {
    dataKey = "week";
  } else {
    dataKey = "month";
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full mr-6 border border-blue-500">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-blue-600">Sale's Graph</h2>
        <select className="p-2 border rounded" onChange={handleChange}>
          <option value="Week">Week</option>
          <option value="Month">Month</option>
          <option value="Yearly">Yearly</option>
        </select>
      </div>
      <div className="mt-6 h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid stroke="#E5E7EB" vertical={false} />
            <XAxis dataKey={dataKey} axisLine={false} tickLine={false} />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#A9A9A9", fontSize: 12 }}
              domain={yDomain}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="#FFA500"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SalesGraph;
