import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import ExpenseTables from "../../../components/tables/ExpenseTable";
import axios from "axios";
import { BaseUrl } from "../../../App";
import Pagination from "../../../components/Helpers/Pagination";
import Spinners from "../../../components/placeholders/Spinners";
import CustomModal from "../../../components/modals/Modal";

export default function ExpenseTable() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [formCategory, setFormCategory] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [date, setDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAmount, setTotalAmount] = useState(null);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchExpenses();
  }, [currentPage, selectedCategory, fromDate, toDate, searchQuery]);

  // Function to fetch categories from API
  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BaseUrl}/api/admin/expense/category`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch expenses from API
  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BaseUrl}/api/admin/expense/getAll`, {
        params: {
          category: selectedCategory !== "All" ? selectedCategory : undefined,
          search: searchQuery,
          fromDate,
          toDate,
          page: currentPage,
        },
      });
      setExpenses(response.data);
      setTotalPages(response.data.totalPages);
      setTotalAmount(response.data.totalAmount);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    } finally {
      setLoading(false);
    }
  };

  // Modal handlers
  const handleSelect = (item) => {
    setSelectedCategory(item);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setNewCategory(false);
    reset();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewCategory(false);
    reset();
  };

  // Filter functions
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // handleSubmit

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${BaseUrl}/api/admin/expense/create`, data);
      setNewCategory(false);
      reset();

      setIsModalOpen(false);
      // setIsSuccess()
      const expenseData = await fetchExpenses(
        currentPage,
        searchQuery,
        fromDate,
        toDate,
        selectedCategory
      );

      setExpenses(expenseData.Expenses);
      setTotalPages(expenseData.pages);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <div className="flex flex-wrap items-center justify-between space-x-3 space-y-3 p-4 lg:space-y-0 lg:space-x-3">
        <h4 className="text-xl font-semibold">Expense</h4>
        <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-0 border rounded-lg px-1 w-full lg:w-auto">
          <label htmlFor="from-date" className="text-gray-700">
            From:
          </label>
          <input
            type="date"
            id="from-date"
            className="border-0 focus:ring-0 rounded px-2 py-1"
            max={date}
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </div>

        <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-0 border rounded-lg px-1 w-full lg:w-auto">
          <label htmlFor="to-date" className="text-gray-700">
            To:
          </label>
          <input
            type="date"
            id="to-date"
            className="border-0 focus:ring-0 rounded px-2 py-1"
            max={date}
            value={toDate}
            onChange={handleToDateChange}
          />
        </div>

        <select
          className="border rounded-md px-2 py-1"
          onChange={(e) => handleSelect(e.target.value)}
        >
          <option value="All">Category: All</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category}
            </option>
          ))}
        </select>

        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-54 py-1 ps-8 text-sm text-gray-900 rounded bg-gray-50 focus:ring-lavender--600 focus:border-lavender--600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lavender--600 dark:focus:border-lavender--600"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        <div className="w-full lg:w-auto">
          <button
            onClick={handleOpenModal}
            className="bg-blue-500 flex items-center gap-2 text-white rounded-md px-5 py-1 w-full lg:w-auto"
          >
            <FaPlus /> Add Expense
          </button>
        </div>
      </div>

      {/* Table */}
      {loading ? (
        <div>
          <Spinners />
        </div>
      ) : (
        <ExpenseTables
          expenses={expenses.expenses}
          loading={loading}
          currentPage={currentPage}
        />
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="flex justify-between p-10 text-xl">
        <h3>
          Paid Amount :{" "}
          <span className="text-orange-500 font-semibold">
            ₹{expenses?.sums?.totalAmount?.toLocaleString("en-IN")}
          </span>
        </h3>
        <h3>
          Unpaid Amount :
          <span className="text-red-500 font-semibold">
            {" "}
            ₹{expenses?.sums?.totalUnpaid?.toLocaleString()}
          </span>
        </h3>
        <h3>
          Total Amount :{" "}
          <span className="text-green-500 font-semibold">
            {" "}
            ₹{expenses?.sums?.totalPaid.toLocaleString()}
          </span>
        </h3>
      </div>

      {/* NEW EXPENSE FORM MODAL */}
      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-5">
            <div>
              <label className="block text-lg font-medium text-gray-700">
                Date
              </label>
              <input
                defaultValue={date}
                type="date"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("date", { required: "Date is required" })}
              />
              {errors.date && (
                <p className="text-red-500 text-sm">{errors.date.message}</p>
              )}
            </div>
            <div>
              <label className="flex items-center justify-between text-lg font-medium text-gray-700">
                Category{" "}
                <span
                  className="text-sm underline text-purple-500 cursor-pointer"
                  onClick={() => setNewCategory(true)}
                >
                  Add category
                </span>
              </label>
              {newCategory || categories.length === 0 ? (
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  {...register("category", {
                    required: "Category is required",
                  })}
                />
              ) : (
                <select
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  {...register("category", {
                    required: "Category is required",
                  })}
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              )}
              {errors.category && (
                <p className="text-red-500 text-sm">
                  {errors.category.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-700">
                Sub Category
              </label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("subCategory", {
                  required: "Sub category is required",
                })}
              />
              {errors.subCategory && (
                <p className="text-red-500 text-sm">
                  {errors.subCategory.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-700">
                Amount
              </label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("amount", {
                  required: "Amount is required",
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Amount should be a number with up to two decimal places",
                  },
                })}
              />
              {errors.amount && (
                <p className="text-red-500 text-sm">{errors.amount.message}</p>
              )}
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-700">
                Spent for
              </label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...register("spentFor", {
                  required: "Spent for is required",
                })}
              />
              {errors.spentFor && (
                <p className="text-red-500 text-sm">
                  {errors.spentFor.message}
                </p>
              )}
            </div>
          </div>
          <div className="">
            <label className="block text-lg font-medium text-gray-700 my-3">
              Description
            </label>
            <textarea
              className="border w-full h-32 rounded-lg"
              {...register("description")}
            />
          </div>

          <div className="mt-4 flex gap-10">
            <label className="block text-lg font-medium text-gray-700 my-3">
              Status
            </label>

            <div className="flex items-center space-x-4">
              {/* Paid Radio Button */}
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="paid"
                  {...register("status")}
                  className="form-radio h-5 w-5 text-green-500"
                />
                <span className="text-green-500 font-medium">Paid</span>
              </label>

              {/* Unpaid Radio Button */}
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="un paid"
                  {...register("status")}
                  className="form-radio h-5 w-5 text-red-500"
                />
                <span className="text-red-500 font-medium">Unpaid</span>
              </label>
            </div>
          </div>
          <div className="mt-4 flex gap-3 justify-end">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
              onClick={handleCloseModal}
            >
              Discard
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            >
              Save Expense
            </button>
          </div>
        </form>
      </CustomModal>
    </div>
  );
}
