import React from "react";

const Table = ({ type = "orders" }) => {
  const ordersData = [
    {
      id: 1,
      name: "Praveen",
      location: "Coimbatore",
      mobile: "67567 65291",
      products: "Chips",
    },
    {
      id: 2,
      name: "Gokul",
      location: "Nagercoil",
      mobile: "98354 67328",
      products: "Ground nut",
    },
    // Add more rows...
  ];

  const lowStockData = [
    { code: "26767", product: "Chips", quantity: "20 kgs" },
    { code: "26767", product: "Sidai", quantity: "10 kgs" },
    // Add more rows...
  ];

  const data = type === "orders" ? ordersData : lowStockData;

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg overflow-auto   border border-blue-500" >
      <h3 className="text-xl font-bold mb-4 text-blue-500">
        {type === "orders" ? "Orders" : "Low Stock"}
      </h3>
      <table className="w-full table-auto text-left">
        <thead>
          <tr className="py-2">
            {type === "orders" ? (
              <>
                <th className="py-3">Sl. no.</th>
                <th>Name</th>
                <th>Location</th>
                <th>Mobile Number</th>
                <th>Products</th>
              </>
            ) : (
              <>
                <th className="py-3">Code</th>
                <th>Product</th>
                <th>Quantity</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="border-t">
              {type === "orders" ? (
                <>
                  <td  className="py-3">{row.id}</td>
                  <td>{row.name}</td>
                  <td>{row.location}</td>
                  <td>{row.mobile}</td>
                  <td>{row.products}</td>
                </>
              ) : (
                <>
        
                  {/* <td className="py-3">{row.code}</td>
                  <td>{row.product}</td>
                  <td>{row.quantity}</td> */}
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
