import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { BaseUrl } from "../../../App";
import { useNavigate } from "react-router-dom";

const NewProductForm = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      weightsPrices: [{ weight: "", price: "" }],
    },
  });
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "weightsPrices",
  });
  const [addCategory, setAddCategory] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // percentage calculation for Actual price , mlm dicount , normal discount and referal disocount///////////////////////////////////////////////////////////////
  const [price, setPrice] = useState(0);
  const [mlmPrice, setMlmPrice] = useState(0);
  const [referralPrice, setReferralPrice] = useState(0);
  const [normalPrice, setNormalPrice] = useState(0);
  const productCode = watch("productCode");

  const calculateDiscountedPrice = (basePrice, discount) => {
    if (!discount || !basePrice) return 0;
    return basePrice - (basePrice * discount) / 100;
  };

  // Handlers for MLM, Referral, and Normal Discount
  const handleMlmDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    setMlmPrice(calculateDiscountedPrice(price, discount));
  };

  const handleReferralDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    setReferralPrice(calculateDiscountedPrice(price, discount));
  };

  const handleNormalDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    setNormalPrice(calculateDiscountedPrice(price, discount));
  };
  // ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/api/admin/product/fetch-category?category=Crackers`
        );
        setOptions(response.data.categories);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, []);

  // product instance checking
  const [isProductCodeValid, setIsProductCodeValid] = useState(false);
  const [checkingProductCode, setCheckingProductCode] = useState(false);

  console.log(productCode);

  const checkProductCode = async (productCode) => {
    if (!productCode) return;

    setCheckingProductCode(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/product/not-active/${productCode}`
      );
      console.log(response);

      if (response.data) {
        setIsProductCodeValid(true);
        setOptions([response.data.productCategory]);
        setValue("productName", response.data.productName);
      } else {
        setIsProductCodeValid(true);
      }
    } catch (error) {
      console.error("Error checking product code", error);
      setIsProductCodeValid(false);
    } finally {
      setCheckingProductCode(false);
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      checkProductCode(productCode);
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [productCode, setValue]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("productCode", data.productCode);
      formData.append("productCategory", data.productCategory);
      formData.append("productName", data.productName);
      formData.append("category", "Crackers");
      formData.append("gst", data.gst);
      formData.append("quantity", data.quantity);
      formData.append("unit", data.unit);
      formData.append("pieces", data.pieces);
      formData.append("price", data.price);
      formData.append("mlmDiscount", data.mlmDiscount);
      formData.append("referralDiscount", data.referralDiscount);
      formData.append("normalDiscount", data.normalDiscount);
      formData.append("mlmPrice", mlmPrice);
      formData.append("referralPrice", referralPrice);
      formData.append("normalPrice", normalPrice);

      if (data.photo) {
        Array.from(data.photo).forEach((file) => {
          formData.append("productImage", file);
        });
      }

      const response = await axios.put(
        `${BaseUrl}/api/admin/product/update-instance/${data.productCode}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Product added successfully:", response.data);
      navigate(`/crackers/list/preview/${response.data.product._id}`);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className="m-3 p-5 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-6">New Product</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Product Code */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Product Code
            </label>
            <input
              {...register("productCode", {
                required: "Product Code is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Product Code"
            />
            {errors.productCode && (
              <p className="text-red-500 text-sm">
                {errors.productCode.message}
              </p>
            )}
            {isProductCodeValid && (
              <p className="text-green-500 text-sm">Product details found</p>
            )}
            {checkingProductCode && (
              <p className="text-sm text-gray-500">Checking...</p>
            )}
          </div>

          {/* Product Category */}
          {addCategory ? (
            <div className="flex flex-col">
              <label className="text-gray-700">Product Category</label>
              <input
                type="text"
                {...register("productCategory", {
                  required: "Product Code is required",
                })}
                className="border p-2 rounded mt-1"
                placeholder="SN254"
              />
              {errors.productCategory && (
                <p className="text-red-500 text-sm">
                  {errors.productCategory.message}
                </p>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <label className="text-gray-700">Product Category</label>
                <a
                  onClick={() => setAddCategory(true)}
                  className="text-blue-600 text-sm ml-2"
                >
                  Add New Category
                </a>
              </div>
              <div className="flex items-center">
                <select
                  {...register("productCategory", {
                    required: "Product Category is required",
                  })}
                  className="border p-2 rounded mt-1 w-full capitalize"
                >
                  {options.map((itm, inx) => (
                    <option value={itm} className="capitalize">
                      {itm}
                    </option>
                  ))}
                </select>
              </div>
              {errors.productCategory && (
                <p className="text-red-500 text-sm">
                  {errors.productCategory.message}
                </p>
              )}
            </div>
          )}

          {/* Product Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Product Name
            </label>
            <input
              {...register("productName", {
                required: "Product Name is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Product Name"
            />
            {errors.productName && (
              <p className="text-red-500 text-sm">
                {errors.productName.message}
              </p>
            )}
          </div>

          {/* GST */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              GST
            </label>
            <input
              {...register("gst", { required: "GST is required" })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter GST"
            />
            {errors.gst && (
              <p className="text-red-500 text-sm">{errors.gst.message}</p>
            )}
          </div>

          <div className="relative flex-grow">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Quantity
            </label>
            {/* Flex container for weight input and unit */}
            <div className="flex items-center border border-gray-300 rounded-md shadow-sm">
              <input
                {...register(`quantity`, {
                  required: "quantity is required",
                })}
                type="number"
                placeholder="Quantity"
                className="w-full p-2  border-none rounded-l-md"
              />
              <select
                {...register(`unit`, {
                  required: "Unit is required",
                })}
                className="p-2  border-0 rounded-r-md"
              >
                <option value="pack">pack</option>
                <option value="box">box</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Pieces
            </label>
            <input
              {...register("pieces", { required: "pieces is required" })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter pieces"
            />
            {errors.gst && (
              <p className="text-red-500 text-sm">{errors.pieces.message}</p>
            )}
          </div>
          {/* <div></div> */}

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Price
            </label>
            <input
              {...register("price", { required: "price is required" })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter price"
              onChange={(e) => setPrice(parseFloat(e.target.value) || 0)}
            />
            {errors.gst && (
              <p className="text-red-500 text-sm">{errors.price.message}</p>
            )}
          </div>
          <div></div>

          {/* MLM Discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              MLM Discount
            </label>
            <input
              {...register("mlmDiscount", {
                required: "MLM Discount is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter MLM Discount"
              onChange={handleMlmDiscountChange}
            />
            {errors.mlmDiscount && (
              <p className="text-red-500 text-sm">
                {errors.mlmDiscount.message}
              </p>
            )}
            {mlmPrice > 0 && (
              <p className="text-green-500 font-semibold">
                Discounted MLM Price: {mlmPrice.toFixed(2)} Rs
              </p>
            )}
          </div>

          {/* Referral Discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Referral Discount
            </label>
            <input
              {...register("referralDiscount", {
                required: "Referral Discount is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Referral Discount"
              onChange={handleReferralDiscountChange}
            />
            {errors.referralDiscount && (
              <p className="text-red-500 text-sm">
                {errors.referralDiscount.message}
              </p>
            )}
            {referralPrice > 0 && (
              <p className="text-green-500 font-semibold">
                Discounted Referral Price: {referralPrice.toFixed(2)} Rs
              </p>
            )}
          </div>

          {/* Normal Discount */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Normal Discount
            </label>
            <input
              {...register("normalDiscount", {
                required: "Normal Discount is required",
              })}
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              placeholder="Enter Normal Discount"
              onChange={handleNormalDiscountChange}
            />
            {errors.normalDiscount && (
              <p className="text-red-500 text-sm">
                {errors.normalDiscount.message}
              </p>
            )}
            {normalPrice > 0 && (
              <p className="text-green-500 font-semibold">
                Discounted Normal Price: {normalPrice.toFixed(2)} Rs
              </p>
            )}
          </div>

          {/* Photo Upload */}
          <div className="mb-4 w-full">
            <label className="block mb-3 font-medium">Product Photos</label>
            <div
              className={`w-full border border-dashed border-blue-500 p-2 rounded-md text-center underline text-blue-500 cursor-pointer ${
                errors.photo ? "border-red-600 text-red-600" : ""
              }`}
              onClick={() => document.getElementById("photo").click()}
            >
              {watch("photo") && watch("photo").length > 0
                ? Array.from(watch("photo")).map((file, index) => (
                    <p key={index}>{file.name}</p>
                  ))
                : "Upload images"}
            </div>
            <input
              {...register("photo", {
                required: "Product Image is required",
              })}
              type="file"
              id="photo"
              className="hidden"
              multiple
            />
            {errors.photo && (
              <span className="text-red-600">{errors.photo.message}</span>
            )}
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-8 mt-6">
          <button
            type="button"
            className="text-red-500 py-2 px-4  font-semibold"
          >
            Discard
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewProductForm;
