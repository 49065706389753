import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../../../App";
import moment from "moment";
import { GiFireworkRocket } from "react-icons/gi";
import { Bounce, toast, ToastContainer } from "react-toastify";

const ReviewCard = ({ review }) => {
  const [reviewStatus, setReviewStatus] = useState(review.status);

  // Function to handle review approval
  const handleApprove = async () => {
    try {
      const response = await axios.put(
        `${BaseUrl}/api/admin/orders/review/${review._id}`,
        {
          status: "approved",
        }
      );
      setReviewStatus(response.data.status);

      toast.success("Review Approved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Failed to approve review:", error);
    }
  };

  // Function to handle review rejection
  const handleReject = async () => {
    try {
      const response = await axios.put(
        `${BaseUrl}/api/admin/orders/review/${review._id}`,
        {
          status: "rejected",
        }
      );
      setReviewStatus(response.data.status);

      toast.success("Review Rejected", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Failed to reject review:", error);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
      {/* Header */}
     
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center w-1/2">
          <div className="space-y-4">
            <h3 className="text-xl font-bold text-blue-500">
              {review?.userId?.name}
            </h3>
            <div className="text-sm text-gray-500 space-y-4">
              <p className="text-sm">
                {moment(new Date(review?.createdAt)).format("MMMM DD, YYYY")}
              </p>
              <p className="capitalize">
                {moment(review?.createdAt).fromNow()}
              </p>
            </div>
          </div>
          {/* Rating */}
          <div className="flex mt-4">
            <div>
              <p className="text-blue-500 text-2xl">
                {"★".repeat(review.rating) + "☆".repeat(5 - review.rating)}
              </p>
              <p className="text-lg font-semibold mt-4">{review.comment}</p>
              <p className="text-gray-600 text-sm mt-2">{review.description}</p>
            </div>
            <span className="ml-2 text-gray-500">({review.rating}/5)</span>
            <span
              className={`ml-2 text-lg font-semibold capitalize ${
                reviewStatus === "approved"
                  ? "text-green-500"
                  : reviewStatus === "rejected"
                  ? "text-red-500"
                  : "text-yellow-500"
              }`}
            >
              {reviewStatus}
            </span>
          </div>
        </div>

        {/* Product Category */}
        <div className="flex items-center justify-center gap-3 text-xl text-orange-500 font-serif font-semibold">
          <GiFireworkRocket />
          {review?.productId?.category}
        </div>
      </div>

      {/* Action Buttons and Status */}
      <div className="flex justify-end items-center mt-6">
        <div className="flex space-x-4">
          <button
            className="text-red-500 font-bold"
            onClick={handleReject}
            disabled={reviewStatus === "rejected"}
          >
            Reject
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-1 rounded-md font-semibold"
            onClick={handleApprove}
            disabled={reviewStatus === "approved"}
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch reviews from the API
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.get(`${BaseUrl}/api/admin/orders/review`);
        setReviews(response.data.reviews);
        setError(null);
      } catch (err) {
        setError("Failed to load reviews. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div className="m-3 p-5">
       <ToastContainer />
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-blue-600">Reviews & Ratings</h2>
        {/* 
        Uncomment and implement filter functionality later
        <button className="flex items-center bg-gray-200 px-4 py-2 rounded-lg">
          <span className="text-gray-600 mr-2">Filters</span>
          <svg
            className="w-5 h-5 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707L13 12.414V19a1 1 0 01-.293.707l-2 2A1 1 0 019 21v-8.586l-6.707-6.707A1 1 0 012 6V4z"
            ></path>
          </svg>
        </button>
        */}
      </div>

      <div className="mt-6">
        {/* Display loading state */}
        {loading && <p className="text-center">Loading reviews...</p>}

        {/* Display error message if API call fails */}
        {error && <p className="text-center text-red-500">{error}</p>}

        {/* Display the reviews once loaded */}
        {!loading && !error && reviews.length > 0
          ? reviews.map((review) => (
              <ReviewCard key={review._id} review={review} />
            ))
          : // If no reviews are found
            !loading && <p className="text-center">No reviews found.</p>}
      </div>
    </div>
  );
};

export default Reviews;
