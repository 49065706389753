import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../../../App";
import axios from "axios";
import { Bounce, toast, ToastContainer } from "react-toastify";

export default function NewProduct() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [addCategory, setAddCategory] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/api/admin/product/fetch-category?category=Crackers`
        );
        setOptions(response.data.categories);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${BaseUrl}/api/admin/product/create?category=Crackers`,
        data
      );

      toast.success("Product Created", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      reset();
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="m-3 p-5 min-h-screen bg-white shadow-md rounded-md">
      <ToastContainer />
      <h2 className="text-xl font-bold mb-4">New Product</h2>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="text-gray-700">Product Code</label>
            <input
              type="text"
              {...register("productCode", {
                required: "Product Code is required",
              })}
              className="border p-2 rounded mt-1"
              placeholder="SN254"
            />
            {errors.productCode && (
              <p className="text-red-500 text-sm">
                {errors.productCode.message}
              </p>
            )}
          </div>

          {addCategory ? (
            <div className="flex flex-col">
              <label className="text-gray-700">Product Category</label>
              <input
                type="text"
                {...register("productCategory", {
                  required: "Product Code is required",
                })}
                className="border p-2 rounded mt-1"
                placeholder="SN254"
              />
              {errors.productCategory && (
                <p className="text-red-500 text-sm">
                  {errors.productCategory.message}
                </p>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <label className="text-gray-700">Product Category</label>
                <a
                  onClick={() => setAddCategory(true)}
                  className="text-blue-600 text-sm ml-2"
                >
                  Add New Category
                </a>
              </div>
              <div className="flex items-center">
                <select
                  {...register("productCategory", {
                    required: "Product Category is required",
                  })}
                  className="border p-2 rounded mt-1 w-full capitalize"
                >
                  {options.map((itm, inx) => (
                    <option value={itm} className="capitalize">
                      {itm}
                    </option>
                  ))}
                </select>
              </div>
              {errors.productCategory && (
                <p className="text-red-500 text-sm">
                  {errors.productCategory.message}
                </p>
              )}
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="text-gray-700">Product Name</label>
            <input
              type="text"
              {...register("productName", {
                required: "Product Name is required",
              })}
              className="border p-2 rounded mt-1"
              placeholder="2.75 Bird Crackers"
            />
            {errors.productName && (
              <p className="text-red-500 text-sm">
                {errors.productName.message}
              </p>
            )}
          </div>

          <div className="relative flex-grow">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Quantity
            </label>
            {/* Flex container for weight input and unit */}
            <div className="flex items-center border   rounded-md shadow-sm">
              <input
                {...register(`totalQuantity`, {
                  required: "quantity is required",
                })}
                type="number"
                placeholder="Quantity"
                className="w-full p-2  border-none rounded-l-md"
              />
              <select
                {...register(`totalQuantityUnit`, {
                  required: "Unit is required",
                })}
                className="p-2  border-0 rounded-r-md"
              >
                <option value="pack">pack</option>
                <option value="box">box</option>
              </select>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-10 mt-4">
          <button
            type="button"
            className="text-red-600    px-4 py-2 rounded-md hover:bg-red-50"
          >
            Discard
          </button>
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? "Updating...." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
