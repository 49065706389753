import React from "react";

const Card = ({ title, value, icon }) => {
  return (
    <div className="bg-white border border-blue-500 shadow-lg rounded-lg p-3 py-5 flex  justify-center gap-5 items-center">
      <div>
        <img className="w-12 h-12" src={icon} />
      </div>
      <div>
        <h3 className="text-xl font-semibold text-blue-500 mt-2">{value}</h3>
        <p className="text-gray-500">{title}</p>
      </div>
    </div>
  );
};

export default Card;
