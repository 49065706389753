import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../../../App";
import moment from "moment";
import Spinners from "../../../components/placeholders/Spinners";
import {
  MdOutlineKeyboardBackspace,
  MdOutlineLocalPrintshop,
} from "react-icons/md";
import { useReactToPrint } from "react-to-print";

const CustomerDetails = () => {
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const invoiceRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/orders/details/${id}`
      );

      setOrders(response.data);

      setError(null);
    } catch (error) {
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: `Invoice-${orders?.orderId}`,
  });


  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinners />
      </div>
    );
  }

  return (
    <div className="m-3" ref={invoiceRef}>
      <div className="flex items-center p-2 text-gray-800 gap-3 cursor-pointer">
        <MdOutlineKeyboardBackspace
          size={30}
          onClick={() => navigate(-1)}
          className="cursor-pointer"
        />
        <p className="text-blue-500 font-semibold text-lg">Orders</p>
      </div>
      {/* Header */}
      <div className="bg-white   p-5  shadow-md rounded-md">
        <div className="flex justify-between items-center mb-6  ">
          <h2 className="text-xl font-semibold">Customer details</h2>
          <button   onClick={handlePrint} className="flex items-center justify-center gap-2   border p-2 rounded-md border-blue-500 text-blue-500">
            {" "}
            <MdOutlineLocalPrintshop />
            Print
          </button>
        </div>

        {/* customer details */}
        <div className="grid grid-cols-2  gap-5 py-5  w-3/4">
          <div className="font-semibold">Customer Name</div>
          <div>John</div>
          <div className="font-semibold">Date of Order</div>
          <div> {moment(new Date(orders?.createdAt)).format("DD-MM-YYYY")}</div>
          <div className="font-semibold">Contact Number</div>
          <div>{orders?.user?.phoneNumber}</div>
          <div className="font-semibold">Email ID</div>
          <div>{orders?.user?.email}</div>
          <div className="font-semibold">Billing Address</div>
          <div>
            {orders?.shippingAddress?.address} ,{orders?.shippingAddress?.city}{" "}
            , {orders?.shippingAddress?.district},
            {orders?.shippingAddress?.state},{orders?.shippingAddress?.pincode}
          </div>
        </div>

        {/* Product Details */}
        <div className="my-6">
          <div className="flex items-center gap-10">
            <h3 className="text-lg font-medium mb-4">Product Details</h3>
            <select className="border rounded-md px-2 py-1">
              <option value="all">Product: All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <table className="w-full text-left border-collapse">
            <thead>
              <tr>
                <th className="border-b px-4 py-2">Sl. no.</th>
                <th className="border-b px-4 py-2">Product</th>
                <th className="border-b px-4 py-2">Product Code</th>
                <th className="border-b px-4 py-2">Category</th>
                <th className="border-b px-4 py-2">Product Name</th>
                <th className="border-b px-4 py-2">Quantity</th>
                <th className="border-b px-4 py-2">Weight</th>
                <th className="border-b px-4 py-2">Price</th>
              </tr>
            </thead>
            <tbody>
              {orders?.items?.map((itm, ind) => (
                <tr>
                  <td className="border-b px-4 py-2">{ind + 1}</td>
                  <td className="border-b px-4 py-2">
                    {itm.product.productCategory}
                  </td>
                  <td className="border-b px-4 py-2">
                    {itm.product.productCode}
                  </td>
                  <td className="border-b px-4 py-2">{itm.product.category}</td>
                  <td className="border-b px-4 py-2">
                    {itm.product.productName}
                  </td>
                  <td className="border-b px-4 py-2">{itm.quantity}</td>
                  <td className="border-b px-4 py-2">250 gms</td>
                  <td className="border-b px-4 py-2">
                    Rs. {orders?.totalAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Subtotal Section */}
        <div className="text-right mb-6 space-y-2">
          <p>Subtotal : NA</p>
          <p>GST (10%) : NA</p>
          <p>Discount (10%) : NA</p>
          <p className="font-semibold">Total : Rs. {orders?.totalAmount}</p>
        </div>

        {/* Save Invoice Button */}
        <div className="text-end">
          <button
            className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600"
            onClick={() => navigate(`/orders/invoice/${id}`)}
          >
            View Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
